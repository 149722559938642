<template>
  <div id="sidemenu">
    <router-link :to="{ name:'HomePage' }">
      <img
        class="sidemenu__logo"
        src="@/assets/images/logo-default.svg"
        style="width: 60%;"
        alt="mechanic-image"
      >
    </router-link>
    <button
      class="sidemenu__btn"
      @click="navOpen=!navOpen"
      :class="{active:navOpen}"
    >
      <span class="top" />
      <span class="mid" />
      <span class="bottom" />
    </button>
    <transition name="translateX">
      <nav v-show="navOpen">
        <div class="sidemenu__wrapper">
          <ul class="sidemenu__list">
            <li class="sidemenu__item">
              <router-link
                :to="{ name:'HomePage' }"
                class="mobile-link"
              >
                Главная
              </router-link>
            </li>
            <li class="sidemenu__item">
              <router-link
                :to="{ name:'AboutPage' }"
                class="mobile-link"
              >
                О нас
              </router-link>
            </li>
            <li class="sidemenu__item">
              <router-link
                :to="{ name:'ServicesPage' }"
                class="mobile-link"
              >
                Услуги
              </router-link>
            </li>
            <li class="sidemenu__item">
              <router-link
                :to="{ name:'ContactsPage' }"
                class="mobile-link"
              >
                Контакты
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'MobileMenu',
  data () {
    return {
      navOpen: false
    }  
  },
  mounted() {
      let body = document.querySelector('body');
      let mobileLinks = document.querySelectorAll('.mobile-link');
      let sidemenuBtn = document.querySelector('.sidemenu__btn');
      sidemenuBtn.addEventListener('click', ()=>{
        body.classList.toggle('lock')
      });
      mobileLinks.forEach(link => {
      link.addEventListener('click', ()=>{
        body.classList.remove('lock')
      })
      });
    
    }
}
</script>
